import { Component, forwardRef, input, model, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-password-form-field',
  templateUrl: './password-form-field.component.html',
  styleUrls: ['./password-form-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFormFieldComponent),
      multi: true,
    },
  ],
  host: {
    '(blur)': '_onTouched()',
  },
})
export class PasswordFormFieldComponent implements ControlValueAccessor {
  name = input<string | null>(null);
  placeholder = input<string | null>(null);
  autocomplete = input<string | null>(null);
  id = input<string | null>(null);

  disabled = model(false);

  protected revealed = signal(false);

  _onChange: (value: string) => void = () => undefined;
  _onTouched: () => void = () => undefined;

  writeValue(): void {}

  registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  protected change(value: string): void {
    this._onChange(value);
    this._onTouched();
  }

  protected toggleVisibility(): void {
    this.revealed.update((v) => !v);
  }
}
