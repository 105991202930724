import { Injectable } from '@angular/core';

@Injectable()
export class JWTService {
  #key = 'jwt';

  get token(): string | null {
    return localStorage.getItem(this.#key);
  }

  save(token: string): void {
    localStorage.setItem(this.#key, token);
  }

  clear(): void {
    localStorage.removeItem(this.#key);
  }
}
