import { Component } from '@angular/core';

@Component({
  selector: 'app-locked-page',
  templateUrl: './locked-page.component.html',
  styleUrls: ['./locked-page.component.css'],
})
export class LockedPageComponent {
  constructor() {}
}
