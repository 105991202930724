import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, catchError, finalize, of, takeUntil, tap } from 'rxjs';
import { SnackBar } from '@features/ui/components/snack-bar';

import { AuthService } from '../../services';

type ResetForm = {
  token: FormControl<string>;
};

@Component({
  selector: 'app-reset-page',
  templateUrl: './reset-page.component.html',
  styleUrls: ['./reset-page.component.css'],
})
export class ResetPageComponent implements OnInit, OnDestroy {
  #unsubscribe$ = new Subject<void>();
  protected form: FormGroup<ResetForm>;
  protected loading = signal(false);
  protected auth = inject(AuthService);
  protected builder = inject(FormBuilder);
  protected router = inject(Router);
  protected route = inject(ActivatedRoute);
  protected email = signal('');
  protected snackBar = inject(SnackBar);

  constructor() {
    this.form = this.builder.group({
      token: this.builder.nonNullable.control(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ])
      ),
    });
  }

  ngOnInit(): void {
    this.email.set(
      decodeURIComponent(this.route.snapshot.queryParams['email'] || '')
    );

    this.form.valueChanges
      .pipe(takeUntil(this.#unsubscribe$))
      .subscribe(({ token }) => {
        if (token?.length === 6) {
          this.submit();
        }
      });
  }

  submit() {
    const payload = this.form.getRawValue();

    this.router.navigate(['/password-reset'], {
      queryParams: { token: payload.token },
    });
  }

  resend() {
    this.loading.set(true);

    this.auth
      .recover({ email: this.email() })
      .pipe(
        takeUntil(this.#unsubscribe$),
        tap(() => this.snackBar.success('Code Resent')),
        catchError((error: { message: string }) => {
          this.snackBar.error(error.message);
          return of(error);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }
}
