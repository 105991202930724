import {
  Component,
  OnDestroy,
  OnInit,
  computed,
  input,
  signal,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-password-strength-indicator',
  templateUrl: './password-strength-indicator.component.html',
  styleUrls: ['./password-strength-indicator.component.css'],
  host: {
    '[style.--strength]': 'strength()',
  },
})
export class PasswordStrengthIndicatorComponent implements OnInit, OnDestroy {
  #unsubscribe$ = new Subject<void>();
  /**
   * The control to check the password strength of.
   */
  control = input.required<AbstractControl | null>();

  /**
   * The checks to perform on the password.
   */
  checks = input<string[]>([
    'minlength',
    'uppercase',
    'lowercase',
    'digit',
    'special',
  ]);

  protected strength = signal<number>(0);

  protected label = computed(() =>
    this.strength() < 50 ? 'Weak' : this.strength() < 100 ? 'Medium' : 'Strong'
  );

  constructor() {}

  ngOnInit(): void {
    this.control()
      ?.valueChanges.pipe(
        takeUntil(this.#unsubscribe$),
        tap(() => {
          const control = this.control();
          if (!control?.value) {
            this.strength.set(0);
            return;
          }

          let strength = 0;

          for (const check of this.checks()) {
            if (!control?.hasError(check)) {
              strength += 100 / this.checks().length;
            }
          }

          this.strength.set(strength);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }
}
