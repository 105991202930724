import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Subject,
  catchError,
  finalize,
  from,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { CustomValidators } from '@features/ui/validation';
import { SnackBar } from '@features/ui/components/snack-bar';

import { Reset } from '../../models';
import { AuthService } from '../../services';

type ResetPasswordForm = {
  [k in keyof Reset]: FormControl<Reset[k]>;
};

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css'],
})
export class ResetPasswordPageComponent {
  #unsubscribe$ = new Subject<void>();

  protected form: FormGroup<ResetPasswordForm>;
  protected loading = signal(false);
  protected auth = inject(AuthService);
  protected builder = inject(FormBuilder);
  protected router = inject(Router);
  protected route = inject(ActivatedRoute);
  protected snackBar = inject(SnackBar);

  constructor() {
    this.form = this.builder.group({
      token: this.builder.nonNullable.control(
        this.route.snapshot.queryParams['token'],
        Validators.required
      ),
      password: this.builder.nonNullable.control(
        '',
        Validators.compose([Validators.required, CustomValidators.password])
      ),
      password_confirmation: this.builder.nonNullable.control(
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.sameAs('password'),
        ])
      ),
    });
  }

  submit(): void {
    const payload = this.form.getRawValue();

    this.loading.set(true);

    this.auth
      .reset(payload)
      .pipe(
        takeUntil(this.#unsubscribe$),
        tap(() => this.snackBar.success('Password Reset Successfully')),
        switchMap(() => from(this.router.navigate(['/']))),
        catchError((error: { message: string }) => {
          this.snackBar.error(error.message);
          return of(error);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }
}
