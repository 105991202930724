<input
  #input
  class="input w-full"
  type="{{ revealed() ? 'text' : 'password' }}"
  [attr.name]="name()"
  [attr.id]="id()"
  [attr.placeholder]="placeholder()"
  [attr.autocomplete]="autocomplete()"
  (input)="change(input.value)"
  [disabled]="disabled()"
/>

<div
  class="absolute right-0 top-0 bottom-0 flex flex-col items-center justify-center w-12"
>
  <button
    type="button"
    class="flex flex-col items-center justify-center"
    (click)="toggleVisibility()"
  >
    <span class="material-symbols-outlined text-gray-500">
      {{ revealed() ? "visibility" : "visibility_off" }}
    </span>
  </button>
</div>
