import { inject } from '@angular/core';

import { HttpInterceptorFn } from '@angular/common/http';

import { JWTService } from '../services';

export const httpTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const jwt = inject(JWTService);
  const token = jwt.token;

  if (token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return next(req);
};
