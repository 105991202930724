import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {
  /// Requires at least 1 uppercase letter.
  public static uppercase: ValidatorFn = (control: AbstractControl) => {
    return Validators.pattern(/[A-Z]/)(control) ? { uppercase: true } : null;
  };

  /// Requires at least 1 lowercase letter.
  public static lowercase = (control: AbstractControl) => {
    return Validators.pattern(/[a-z]/)(control) ? { lowercase: true } : null;
  };

  /// Requires at least 1 digit.
  public static digit = (control: AbstractControl) => {
    return Validators.pattern(/[0-9]/)(control) ? { digit: true } : null;
  };

  /// Requires at least 1 special character.
  public static special = (control: AbstractControl) => {
    return Validators.pattern(/[!@#$%^&*(),.?":{}|<>]/)(control)
      ? { special: true }
      : null;
  };

  public static sameAs = (name: string): ValidatorFn => {
    return (control: AbstractControl) => {
      const error =
        control.value !== control.parent?.get(name)?.value
          ? { mismatch: true }
          : null;
      return error;
    };
  };

  public static password = Validators.compose([
    Validators.required, // required
    Validators.minLength(8), // at least 8 characters
    CustomValidators.uppercase,
    CustomValidators.lowercase,
    CustomValidators.digit,
    CustomValidators.special,
  ]);
}
