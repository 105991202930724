<div
  class="flex flex-col items-center justify-center p-8 landscape:justify-start landscape:items-start"
>
  <img class="h-8" src="/assets/images/logo.svg" alt="Reakt360" />
</div>

<div class="p-4 flex flex-col landscape:justify-center landscape:items-center">
  <div
    class="landscape:p-12 landscape:bg-white landscape:rounded-lg landscape:max-w-mobile landscape:w-full landscape:shadow-sm portrait:h-full"
  >
    <form
      class="flex flex-col items-stretch justify-start gap-8 portrait:h-full"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div>
        <app-back-button></app-back-button>
      </div>

      <legend class="text-3xl font-normal font-display">Forgot Password</legend>

      <p class="text-sm font-normal">
        We have sent you a confirmation code to your email, please enter this
        code below:
      </p>

      <fieldset
        [disabled]="loading()"
        class="flex flex-col gap-8 items-stretch flex-1"
      >
        <div>
          <label class="sr-only" for="token">Access Code</label>
          <input
            id="token"
            class="input w-full"
            type="text"
            formControlName="token"
            placeholder="Enter access code"
            autocomplete="one-time-code"
          />

          <app-form-error
            [control]="form.get('token')"
            [errors]="{
              required: 'Access code is required.'
            }"
          ></app-form-error>
        </div>

        <div>
          <button
            [disabled]="loading()"
            class="underline text-gray-400 text-xs"
            (click)="resend()"
            title="Resend code"
          >
            Resend Code
          </button>
        </div>

        <div class="flex flex-col items-stretch flex-1 justify-end">
          @if(loading()){
          <app-spinner></app-spinner>
          } @else {
          <input
            [disabled]="form.invalid"
            class="filled-button"
            type="submit"
            value="Submit"
          />
          }
        </div>
      </fieldset>
    </form>
  </div>
</div>
