import { Component, OnDestroy, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Subject,
  catchError,
  finalize,
  from,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { SnackBar } from '@features/ui/components/snack-bar';

import { Recovery } from '../../models';
import { AuthService } from '../../services';

type RecoverForm = {
  [k in keyof Recovery]: FormControl<Recovery[k]>;
};

@Component({
  selector: 'app-recover-page',
  templateUrl: './recover-page.component.html',
  styleUrls: ['./recover-page.component.css'],
})
export class RecoverPageComponent implements OnDestroy {
  #unsubscribe = new Subject<void>();

  protected form: FormGroup<RecoverForm>;
  protected loading = signal(false);
  protected auth = inject(AuthService);
  protected builder = inject(FormBuilder);
  protected router = inject(Router);
  protected snackBar = inject(SnackBar);

  constructor() {
    this.form = this.builder.group({
      email: this.builder.nonNullable.control(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
    });
  }

  submit(): void {
    const payload = this.form.getRawValue();

    this.loading.set(true);

    this.auth
      .recover(payload)
      .pipe(
        takeUntil(this.#unsubscribe),
        switchMap(() =>
          from(
            this.router.navigate(['/reset'], {
              queryParams: { email: encodeURIComponent(payload.email) },
            })
          )
        ),
        catchError((error: { message: string }) => {
          this.snackBar.error(error.message);
          return of(error);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#unsubscribe.next();
    this.#unsubscribe.complete();
  }
}
