<div
  class="flex flex-col items-center justify-center p-8 landscape:justify-start landscape:items-start"
>
  <img
    class="h-10 landscape:h-8"
    src="/assets/images/logo.svg"
    alt="Reakt360"
  />
</div>

<div
  class="p-4 flex flex-col justify-end landscape:justify-center landscape:items-center"
>
  <div
    class="landscape:p-12 landscape:bg-white landscape:rounded-lg landscape:max-w-mobile landscape:w-full landscape:shadow-sm"
  >
    <form
      class="flex flex-col items-stretch justify-start gap-8"
      [formGroup]="form"
      (ngSubmit)="login()"
    >
      <legend class="font-body text-3xl font-normal font-display">Login</legend>

      <fieldset
        [disabled]="loading()"
        class="flex flex-col gap-8 items-stretch"
      >
        <div>
          <label class="sr-only" for="email">Email</label>
          <input
            class="input w-full"
            type="email"
            id="email"
            formControlName="email"
            placeholder="Email"
            autocomplete="email"
          />

          <app-form-error
            [control]="form.get('email')"
            [errors]="{
              email: 'Must be a valid email address',
              required: 'Email is required'
            }"
          ></app-form-error>
        </div>

        <div>
          <label class="sr-only" for="password">Password</label>
          <app-password-form-field
            class="w-full"
            id="password"
            formControlName="password"
            placeholder="Password"
          ></app-password-form-field>

          <app-form-error
            [control]="form.get('password')"
            [errors]="{
              required: 'Password is required'
            }"
          ></app-form-error>
        </div>

        <div>
          <a
            class="underline text-gray-400 text-xs"
            [routerLink]="['/recover']"
            title="Forgot password"
          >
            Forgot Password
          </a>
        </div>

        @if(loading()){
        <app-spinner></app-spinner>
        } @else {
        <input
          [disabled]="form.invalid"
          class="filled-button"
          type="submit"
          value="Login"
        />
        }
      </fieldset>
    </form>
  </div>
</div>
