<div
  class="flex flex-col items-center justify-center p-4 landscape:p-8 landscape:justify-start landscape:items-start"
>
  <img class="h-8" src="/assets/images/logo.svg" alt="Reakt360" />
</div>

<div class="p-4 flex flex-col landscape:justify-center landscape:items-center">
  <div
    class="landscape:p-12 landscape:bg-white landscape:rounded-lg landscape:max-w-mobile landscape:w-full landscape:shadow-sm portrait:h-full"
  >
    <form
      class="flex flex-col items-stretch justify-start gap-8 portrait:h-full"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div>
        <app-back-button></app-back-button>
      </div>

      <legend class="text-3xl font-normal font-display">Forgot Password</legend>

      <p class="text-sm font-normal">Please enter your email address below.</p>

      <fieldset
        [disabled]="loading()"
        class="flex flex-col gap-8 items-stretch flex-1"
      >
        <div>
          <label class="sr-only" for="email">Email</label>
          <input
            id="email"
            class="input w-full"
            type="email"
            formControlName="email"
            placeholder="Enter email address"
            autocomplete="email"
          />

          <app-form-error
            [control]="form.get('email')"
            [errors]="{
              email: 'Must be a valid email address.',
              required: 'Email is required.'
            }"
          ></app-form-error>
        </div>

        <div class="flex flex-col items-stretch flex-1 justify-end">
          @if(loading()){
          <app-spinner></app-spinner>
          } @else {
          <input
            class="filled-button"
            type="submit"
            value="Next"
            [disabled]="form.invalid"
          />
          }
        </div>
      </fieldset>
    </form>
  </div>
</div>
