import { Component, OnDestroy, inject, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, catchError, finalize, of, switchMap, takeUntil } from 'rxjs';

import { SnackBar } from '@features/ui/components/snack-bar';
import { CustomValidators } from '@features/ui/validation';

import { Activation } from '../../models';
import { AuthService } from '../../services';

type ActivationForm = {
  [k in keyof Activation]: FormControl<Activation[k]>;
};

@Component({
  selector: 'app-activate-page',
  templateUrl: './activate-page.component.html',
  styleUrls: ['./activate-page.component.css'],
})
export class ActivatePageComponent implements OnDestroy {
  #unsubscribe = new Subject<void>();

  protected form: FormGroup<ActivationForm>;
  protected builder = inject(FormBuilder);
  protected route = inject(ActivatedRoute);
  protected loading = signal<boolean>(false);

  protected auth = inject(AuthService);
  protected router = inject(Router);
  protected snackBar = inject(SnackBar);

  constructor() {
    this.form = this.builder.group({
      user_id: this.builder.nonNullable.control(
        this.route.snapshot.queryParams['user_id'],
        Validators.required
      ),
      code: this.builder.nonNullable.control(
        this.route.snapshot.queryParams['code'],
        Validators.required
      ),
      password: this.builder.nonNullable.control('', CustomValidators.password),
      password_confirmation: this.builder.nonNullable.control(
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.sameAs('password'),
        ])
      ),
      gdpr_accepted: this.builder.nonNullable.control(
        false,
        Validators.requiredTrue
      ),
      marketing_accepted: this.builder.nonNullable.control(
        false,
        Validators.required
      ),
    });
  }

  submit(): void {
    const payload = this.form.getRawValue();

    this.loading.set(true);

    this.auth
      .activate(payload)
      .pipe(
        takeUntil(this.#unsubscribe),
        switchMap(() => of(this.router.navigate(['/']))),
        catchError((error: { message: string }) => {
          this.snackBar.error(error.message);
          return of(error);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#unsubscribe.next();
    this.#unsubscribe.complete();
  }
}
