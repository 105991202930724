import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  PasswordFormFieldComponent,
  PasswordStrengthIndicatorComponent,
} from './components';

@NgModule({
  declarations: [
    PasswordFormFieldComponent,
    PasswordStrengthIndicatorComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [PasswordFormFieldComponent, PasswordStrengthIndicatorComponent],
  providers: [],
})
export class PasswordFormFieldModule {}
