import { Component, OnDestroy, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Subject,
  catchError,
  finalize,
  from,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';

import { SnackBar } from '@features/ui/components/snack-bar';
import { AuthService } from '../../services';
import { Login } from '../../models';

type LoginForm = {
  [k in keyof Login]: FormControl<Login[k]>;
};

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnDestroy {
  #unsubscribe = new Subject<void>();

  protected form: FormGroup<LoginForm>;
  protected loading = signal(false);

  protected auth = inject(AuthService);
  protected builder = inject(FormBuilder);
  protected router = inject(Router);
  protected snackBar = inject(SnackBar);

  constructor() {
    this.form = this.builder.group({
      email: this.builder.nonNullable.control('', {
        validators: Validators.compose([Validators.required, Validators.email]),
      }),
      password: this.builder.nonNullable.control('', {
        validators: Validators.compose([Validators.required]),
      }),
    });
  }

  login() {
    const payload = this.form.getRawValue();

    this.loading.set(true);

    this.auth
      .login(payload)
      .pipe(
        takeUntil(this.#unsubscribe),
        switchMap((auth) =>
          from(
            this.router.navigate(['/challenge'], {
              queryParams: { token: auth.login_token },
            })
          )
        ),
        catchError((error: { failed_count?: number; message: string }) => {
          if (error.failed_count === 3) {
            return from(this.router.navigate(['/locked']));
          }

          this.snackBar.error(error.message);

          return of(error);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#unsubscribe.next();
    this.#unsubscribe.complete();
  }
}
