import {
  Component,
  OnDestroy,
  OnInit,
  inject,
  model,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@features/auth/services';
import { SnackBar } from '@features/ui/components/snack-bar';
import {
  Subject,
  catchError,
  finalize,
  from,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

interface ChallengeForm {
  code: FormControl<string>;
}

@Component({
  selector: 'app-challenge-page',
  templateUrl: './challenge-page.component.html',
  styleUrls: ['./challenge-page.component.css'],
})
export class ChallengePageComponent implements OnInit, OnDestroy {
  #unsubscribe = new Subject<void>();

  protected form: FormGroup<ChallengeForm>;
  protected loading = signal(false);

  protected auth = inject(AuthService);
  protected builder = inject(FormBuilder);
  protected router = inject(Router);
  protected route = inject(ActivatedRoute);
  protected token = model<string>('');
  protected snackBar = inject(SnackBar);

  constructor() {
    this.form = this.builder.group({
      code: this.builder.nonNullable.control(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ])
      ),
    });
  }

  ngOnInit(): void {
    this.token.set(this.route.snapshot.queryParams['token']);

    this.form.valueChanges
      .pipe(takeUntil(this.#unsubscribe))
      .subscribe(({ code }) => {
        if (code?.length === 6) {
          this.submit();
        }
      });
  }

  submit() {
    const payload = this.form.getRawValue();
    this.loading.set(true);

    this.auth
      .confirm2fa(this.token(), payload.code)
      .pipe(
        takeUntil(this.#unsubscribe),
        switchMap(() => from(this.router.navigateByUrl('/'))),
        catchError((error: { message: string }) => {
          this.snackBar.error(error.message);
          return of(error);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  resend() {
    this.loading.set(true);

    this.auth
      .resend2fa(this.token())
      .pipe(
        takeUntil(this.#unsubscribe),
        tap(() => this.snackBar.success('Code Resent')),
        catchError((error: { message: string }) => {
          this.snackBar.error(error.message);
          return of(error);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#unsubscribe.next();
    this.#unsubscribe.complete();
  }
}
