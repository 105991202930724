import { Route } from '@angular/router';
import {
  ActivatePageComponent,
  ChallengePageComponent,
  LockedPageComponent,
  LoginPageComponent,
  RecoverPageComponent,
  ResetPageComponent,
  ResetPasswordPageComponent,
} from './pages';
import { noAuthGuard } from './guards';

export const routes: Route[] = [
  {
    path: 'login',
    title: 'Login',
    component: LoginPageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'challenge',
    title: '2FA Challenge',
    component: ChallengePageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'complete-account',
    title: 'Complete Account',
    component: ActivatePageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'recover',
    title: 'Account Recovery',
    component: RecoverPageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'reset',
    title: 'Reset Password',
    component: ResetPageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'password-reset',
    title: 'Reset Password',
    component: ResetPasswordPageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'locked',
    title: 'Account Locked',
    component: LockedPageComponent,
    canActivate: [noAuthGuard],
  },
];
